import { Outlet } from "react-router-dom";
//import { Container } from 'react-bootstrap';
import HeaderAdmin from "./../header_footer/AdminHeader";
import FooterAdmin from "./../header_footer/AdminFoter";
const LayoutAdmin = () => {
  return (
    <div style={{color: '#626667'}}>
      <HeaderAdmin />
      <Outlet />
      <FooterAdmin />
    </div>
  );
};
export default LayoutAdmin;