/**
 * Pięć klas kodów odpowiedzi HTTP
 * 100-199 – kody informacyjne.
 * 200-299 – kody powodzenia.
 * 300-399 – kody przekierowań
 * 400-499 – kody błędów klienta.
 * 500-599 – kody błędu serwera HTTP.
 * 
 * Tutorial Toastify:
 * https://geshan.com.np/blog/2023/05/react-toastify/
 * 
 * Tutorial wylapywanie bledow
 * https://dmitripavlutin.com/javascript-fetch-async-await/
 */
import { useRef, useState, useEffect } from 'react';
import { Routes, Route, Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import './AdminLoginScreen.css';

import axios from 'axios'
axios.defaults.withCredentials = true

//const LOGIN_URL = '/auth';
//const LOGIN_URL = 'http://localhost:5000/api/users/zalogujuzytkownika';

const AdminRegisterScreen = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const pathAfterLogin = "/admin";
  
    const userRef = useRef();
    const errRef = useRef();
	
    const [name, setUserName] = useState('');
	const [email, setEmail] = useState('');
    const [password, setPwd] = useState('');
	
	
    const [errMsg, setErrMsg] = useState('');
    
    useEffect(() => {
		 
    }, [])

	useEffect(() => {
        setErrMsg('');
		console.log(name+ " / "+email+" / "+ password);
    }, [name, email, password])

    const REGISTER_URL = 'http://localhost:5000/api/users/registeruser';
    const handleSubmit = async (e) => {
        e.preventDefault();
		console.log("handleSubmit");
		try {
            const response = await axios.post(REGISTER_URL,
                JSON.stringify({ name, email, password }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            console.log(JSON.stringify(response?.data));
            //console.log(JSON.stringify(response));
            //const accessToken = response?.data?.accessToken;
            //const roles = response?.data?.roles;
            //setAuth({ user, pwd, roles, accessToken });
            setEmail('');
            setPwd('');
			
			if (response.status < 400) {
                navigate(pathAfterLogin, { replace: true });
            }else{

            }
			
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            //errRef.current.focus();
        } 
  }//END const handleSubmit = async (e) => {

  return (
    <div className="container-center-width-500">
	  <p><b>Rejestracja użytkownika</b></p>
	   <form onSubmit={handleSubmit}>
                <label htmlFor="name">Nazwa użytkownika:</label>
                <input
                    type="text"
                    id="name"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUserName(e.target.value)}
                    value={name}
                    required
                />
				
				<label htmlFor="email">Email użytkownika:</label>
                <input
                    type="email"
                    id="email"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                />
				
                <label htmlFor="password">Password:</label>
                <input
                    type="password"
                    id="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={password}
                    required
                />
                <button>Rejestruj użytkownika</button>
            </form>
            <p>
                Masz konto?<br />
                <span className="line">
                    <Link to="/login">Zaloguj</Link>
                </span>
            </p>
	</div>
  );
};

export default AdminRegisterScreen;
