import { Outlet, Link, Routes, Route, NavLink, useNavigate, useLocation } from 'react-router-dom';

/**
 * Skopiowane z Dave Gray Axios
 */
import { useContext } from "react";
import { useRef, useState, useEffect } from 'react';
import AuthContext from "../context/AuthProvider";
/**
 * END Skopiowane z Dave Gray Axios
 */

//import AdminHome from '../components/AdminHome';
//import './css/AdminHomeCSS.css';

/**
   * Jesli istnieje userInfo to załaduj  <AdminHome />
   * Jesli user niezalogowany to zaladuj sciezke: '/admin/login'
   */
  //const { userInfo } = useSelector((state) => state.auth);
  //return userInfo ? <AdminHome /> : <Navigate to='/admin/login' replace />;


const HomeScreen = () => {
	
	const { setAuth } = useContext(AuthContext);
	const { auth } = useContext(AuthContext);
    const navigate = useNavigate();
	
	useEffect(() => {
        if(!auth.user){
			navigate('/admin/login');
		}
    }, [])
	
  return (
    <div className='container-center-width-500 container-flexbox'>
	
      <div className="div-inside-container-flexbox">

      <Link to="/admin/rolesuserdictionary">
      <img src="/Icon_Edit_User_Transparent.png" style={{ width: 100 }} alt="profile" />
      <br></br>
        Edycja roli użytkownika
        </Link>

      </div>
	  
      <div className="div-inside-container-flexbox">
      <Link to="/admin/categoryentries">
      <img src="/Icon_Category_Folder_PNG_Transparent.png" style={{ width: 70 }} alt="profile" />
      <br></br>
        Kategorie wpisów</Link>
      </div>
	  
	  
	  <div className="div-inside-container-flexbox">
      <Link to="/admin/categoryentries">
      <img src="/Icon_Users_PNG_Transparent.png" style={{ width: 70 }} alt="icon-users" />
      <br></br>
        Zarządzanie użytkownikami</Link>
      </div>
	  
	  <div className="div-inside-container-flexbox">
      <Link to="/admin/test-request-token">
        Testy REQUEST Z TOKENEM / SPRAWDZANIE TOKENA</Link>
      </div>
	  
	  <div className="div-inside-container-flexbox">
      
	  <Link to="/admin/lista-uzytkownikow1">Lista użytkowników 1</Link>
			<br />
            <Link to="/admin/lista-uzytkownikow2">Lista użytkowników 2</Link>
			<br />
			<p></p>
            <Link to="/admin/list-pages">Admin List Pages</Link>
	  
      </div>
	  
	  
    </div>
  );
};
export default HomeScreen;