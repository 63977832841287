import React from 'react'
//import { Switch, Routes, Route, Link, NavLink } from 'react-router-dom' //Swith nieaktualne dla wersji 6 routera
import { Routes, Route, Link, NavLink } from 'react-router-dom'
import "./../components/components.css"



const Footer = () => {
    return (
       <div className="admin-footer">
        <div id="admin-footer" className="middle margin-top-10px">
		
		<div><p></p></div>
		<div><p></p></div>
                <hr id="footer-hr"/>
				
				
				
                Ryszard Jaklewicz 2023&nbsp;&nbsp;&nbsp;
                <img src="/express-logo-png-01.png" style={{ width: 70 }} alt="profile" />
				&nbsp;&nbsp;&nbsp;React <img src="/logo192.png" style={{ width: 30 }} alt="profile" />
				&nbsp;&nbsp;&nbsp;<img src="/mongodb-logo-poziom-01.png" style={{ width: 70 }} alt="profile" />
                &nbsp;&nbsp;&nbsp;<img src="/mongoose-logo-headtext.jpg" style={{ width: 70 }} alt="profile" />
               
      

            </div>
			{/* 
 &nbsp;&nbsp;&nbsp;React <img src="/logo192.png" style={{ width: 30 }} alt="profile" />
                &nbsp;&nbsp;&nbsp;<img src="/mongodb-logo-poziom-01.png" style={{ width: 70 }} alt="profile" />
                &nbsp;&nbsp;&nbsp;<img src="/mongoose-logo-headtext.jpg" style={{ width: 70 }} alt="profile" />

	  */}
      </div>
	  
    )
}

export default Footer







      
        
      
  
