import { Outlet } from "react-router-dom";
import PublicHeader from "./../header_footer/PublicHeader";
import PublicFooter from "./../header_footer/PublicFooter";

const PublicLayout = () => {
  return (
    <div>
      <PublicHeader />
      <Outlet />
      <PublicFooter />
    </div>
  );
};

export default PublicLayout;
