import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, NavLink, useNavigate } from 'react-router-dom'
import { useContext } from "react";




const HeaderPublic = () => {
	
	
		  return (
			
			<header id="header">
		<div id="div-header-demo">
		
		<Link to="/admin">Panel Admin</Link>
		<br></br>
			To jest HeaderPublic
			<br></br>
			<p>########################################################</p>
		</div>
		</header>
			
			)
		  
           
        
    
	
}	

export default HeaderPublic;
