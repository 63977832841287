import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './logo.svg';
import './App.css';



/**
 * #####################################################
 * Import komponenty PIERWOTNE ORYGINALNE
 */

//import Register from './components/Register';
//import Login from './components/Login';
import Home from './components/Home';
import Layout from './components/Layout';
import Editor from './components/Editor';
import Admin from './components/Admin';
import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';
import Lounge from './components/Lounge';
import LinkPage from './components/LinkPage';
import RequireAuth from './components/RequireAuth';

import Users1 from './components/ListUsers-1';
import Users2 from './components/ListUsers-2';




/**
 * #####################################################
 * Import komponenty publicznej czesci aplikacji
 * Wszystkie pliki części publicznej zaczynaja sie od slowa "Public"
 */
import PublicLayout from "./layout/PublicLayout";
import PublicHome from "./screens/PublicHome";
import PublicPage1 from "./screens/PublicPage1";
import PublicPage2 from "./screens/PublicPage2";
import PublicNotFound from "./screens/NotFoundPublic";


/**
 * #####################################################
 * Import komponenty administracyjnej czesci aplikacji
 * 
 */
import AdminLayout from "./layout/AdminLayout";

import AdminLogin from './components/AdminLogin';
import AdminRegister from './components/AdminRegister';

import AdminHomeScreen from './screens/AdminHomeScreen';
import AdminLoginScreen from './screens/AdminLoginScreen.jsx';
import AdminRegisterScreen from './screens/AdminRegisterScreen.jsx';
import AdminRolesUserDictionaryComponent from './components/AdminRolesUserDictionaryComponent.jsx';
import AdminTestRequestToken from './components/AdminTestRequestToken.jsx';
import AdminNotFound from "./screens/NotFoundAdmin";

import AdminListPages from './components/AdminListPages';







const ROLES = {
  'User': 2001,
  'Editor': 1984,
  'Admin': 5150
}

function App() {


return (
		 <BrowserRouter>
      <Routes>
	      {/* Ściezki publicznej części aplikacji */}
        <Route path="/" element={<PublicLayout />}>
          <Route index element={<PublicHome />} />
          <Route path="page1" element={<PublicPage1 />} />
          <Route path="page2" element={<PublicPage2 />} />
          <Route path="*" element={<PublicNotFound />} />
        </Route>
		    {/* END Ściezki publicznej części aplikacji */}


        {/* ############# CZESC ADMIN ######################### */}


        {/* Sekcja ścieżek administracyjnych */}
        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<AdminHomeScreen />} />
		  
          <Route path='/admin/loginold' element={<AdminLoginScreen />} />
		  
		  <Route path='/admin/login' element={<AdminLogin />} />
		  <Route path="/admin/register" element={<AdminRegister />} />
		  <Route path='/admin/rolesuserdictionary' element={<AdminRolesUserDictionaryComponent />} />
		  
		  
		  <Route path='/admin/registeruser' element={<AdminRegisterScreen />} />
		  <Route path='/admin/test-request-token' element={<AdminTestRequestToken />} />
		  
		  <Route path="/admin/lista-uzytkownikow1" element={<Users1 />} />
		<Route path="/admin/lista-uzytkownikow2" element={<Users2 />} />
		
		<Route path="/admin/list-pages" element={<AdminListPages />} />
		  
          <Route path="*" element={<AdminNotFound />} />
        </Route>

        
		    {/* END Sekcja ścieżek administracyjnych */}


    
      </Routes>
    </BrowserRouter>
	);



	{/*
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        //public routes
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="linkpage" element={<LinkPage />} />
        <Route path="unauthorized" element={<Unauthorized />} />
		
		<Route path="lista-uzytkownikow1" element={<Users1 />} />
		<Route path="lista-uzytkownikow2" element={<Users2 />} />

        //we want to protect these routes
        <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route path="/" element={<Home />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.Editor]} />}>
          <Route path="editor" element={<Editor />} />
        </Route>


        <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
          <Route path="admin" element={<Admin />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[ROLES.Editor, ROLES.Admin]} />}>
          <Route path="lounge" element={<Lounge />} />
        </Route>

        //catch all
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
	*/}
  
}

export default App;