/**
 * Pięć klas kodów odpowiedzi HTTP
 * 100-199 – kody informacyjne.
 * 200-299 – kody powodzenia.
 * 300-399 – kody przekierowań
 * 400-499 – kody błędów klienta.
 * 500-599 – kody błędu serwera HTTP.
 * 
 * Tutorial Toastify:
 * https://geshan.com.np/blog/2023/05/react-toastify/
 * 
 * Tutorial wylapywanie bledow
 * https://dmitripavlutin.com/javascript-fetch-async-await/
 */
import React, { Component, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ReactPaginate from 'react-paginate';
import { useRef } from "react";
//Import na potrzeby okien modalnych bootstrap
//import Modal from 'react-bootstrap/Modal';
//import Button from 'react-bootstrap/Button';
///import Form from 'react-bootstrap/Form';
import './AdminRolesUserDictionaryComponent.css';
import './../css/modal.css';
//Komunikaty toastify
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let messageResponse = "";
let showTablesData = true;

const RolesUserDictionaryComponent = () => {
  
  /** Wyslij PUT UPDATE */
  const test = async () => {
    console.log("test");
    
    /** 
     * Nowe wartosci pol form znajduja sie w state komponentu 
     * const [stateRoleNameUpdate, setNameRoleUpdate] = useState("");
     * const [stateRoleDescriptionUpdate, setDescriptionRoleUpdate] = useState("");
    */
    let response;
    let zmienna1 = "zmienna1";
    let zmienna2 = "zmienna2";
  
    try {
        response = await fetch("http://localhost:5000/api/users/123/rolesusersdictionary", {
                method: "POST",
              })

              /**
               * Zapytanie udało się 
               * Wystepują kody HTTP 100-299
               */
            if (response?.ok) {
                
                const messageFromServer = await response.json();
                console.log('Response OK '+messageFromServer.message);
          
                toast.success(messageFromServer.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000, //3 seconds
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    transition: Slide
                  });
                  //showBoxInfoResponseOK();
                  messageResponse = messageFromServer.message;
        
            } else {

                const messageFromServer = await response.json();
                
                toast.error('Wystąpił błąd: '+messageFromServer.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                //showBoxInfoResponseError();
                messageResponse = "Wystąpił błąd: "+messageFromServer.message;

                console.log(`HTTP Response Code: ${response?.status}`)
                
            }//END if (response?.ok) {

    } catch (error) {
            /** Wystapił błąd */
            console.log('Całe zapytanie sie nie udało', error);
            toast.error('Wystąpił błąd: '+error, {
                position: toast.POSITION.TOP_RIGHT
            });
            //showBoxInfoResponseError();
            messageResponse = "Wystąpił błąd: "+error;
    }
    
  }//END const handleSendFormUpdateRoleUser = async () => {
	  
	  
	  
	/** Wyslij GET */
  const testGetServerToken = async () => {
    console.log("testPutUpdateServerToken");
    
    /** 
     * Nowe wartosci pol form znajduja sie w state komponentu 
     * const [stateRoleNameUpdate, setNameRoleUpdate] = useState("");
     * const [stateRoleDescriptionUpdate, setDescriptionRoleUpdate] = useState("");
    */
    let response;
    let zmienna1 = "zmienna1";
    let zmienna2 = "zmienna2";
  
    try {
        response = await fetch("http://localhost:5000/api/users/api/users/roles-users-dictionary", {
                method: "GET",
                crossDomain: true,
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  "Access-Control-Allow-Origin": "*",
                },
              })

              /**
               * Zapytanie udało się 
               * Wystepują kody HTTP 100-299
               */
            if (response?.ok) {
                
                const messageFromServer = await response.json();
                console.log('Response OK '+messageFromServer.message);
          
                toast.success(messageFromServer.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000, //3 seconds
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    transition: Slide
                  });
                  //showBoxInfoResponseOK();
                  messageResponse = messageFromServer.message;
        
            } else {

                const messageFromServer = await response.json();
                
                toast.error('Wystąpił błąd: '+messageFromServer.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                //showBoxInfoResponseError();
                messageResponse = "Wystąpił błąd: "+messageFromServer.message;

                console.log(`HTTP Response Code: ${response?.status}`)
                
            }//END if (response?.ok) {

    } catch (error) {
            /** Wystapił błąd */
            console.log('Całe zapytanie sie nie udało', error);
            toast.error('Wystąpił błąd: '+error, {
                position: toast.POSITION.TOP_RIGHT
            });
            //showBoxInfoResponseError();
            messageResponse = "Wystąpił błąd: "+error;
    }
    
  }//END const handleSendFormUpdateRoleUser = async () => {
	  
	  
	  
	  
	  
    

    useEffect(() => {
     
    }, []);

  
return (
    <>
      <div className="containerRoleEdit">
	      <Link onClick={test}>Request na serwer FUNKCJA TEST</Link>&nbsp;&nbsp;

        <p></p>
        <p></p>
		<Link onClick={testGetServerToken}>Request na serwer test TOKEN GET</Link>&nbsp;&nbsp;
        <p></p>
        <p></p>
      </div>
    </>
  ); 
};
export default RolesUserDictionaryComponent;

  
