import { useNavigate, Link } from "react-router-dom";
import { useContext } from "react";
import { useRef, useState, useEffect } from 'react';
import AuthContext from "../context/AuthProvider";

const AdminListPages = () => {
    const { setAuth } = useContext(AuthContext);
	const { auth } = useContext(AuthContext);
    const navigate = useNavigate();

	/** Tak sie nie da */
	console.log("auth.user - "+auth.user);
	console.log("auth.roles - "+auth.roles);

    const logout = async () => {
        // if used in more components, this should be in context 
        // axios to /logout endpoint 
        setAuth({});
        navigate('/admin');
    }
	
	useEffect(() => {
		//RJ to działą
        if(!auth.user){
			//navigate('/linkpage');
		}
    }, [])

    return (
        <section>
		<div>
		Po zalogowaniu w pliku Login.js zastosowano navigate from (sciezka"/")
		A sciezka "/" jest skojarzona z komponentem Home.js
		</div>
		<div className="flexGrow">
                <button onClick={logout}>Wyloguj funkcja logout</button>
            </div>
            <h1>Home</h1>
            <br />
            <p>Jesteś zalogowany</p>
			<br />
			
			<Link to="/lista-users">Lista użytkowników</Link>
			
            <br />
            <Link to="/editor">Go to the Editor page</Link>
            <br />
            <Link to="/admin">Go to the Admin page</Link>
            <br />
            <Link to="/lounge">Go to the Lounge</Link>
            <br />
            <Link to="/linkpage">Go to the link page</Link>
            <div className="flexGrow">
                <button onClick={logout}>Wyloguj funkcja logout</button>
            </div>
        </section>
    )
}

export default AdminListPages
