const NotFound = () => {
  return (
    <div className="container-center-width-500">
	<div className="center-text">
	  <p><b>Page Not Found</b></p>
	  <img src="/page-not-found-error-red-triangle.png" style={{ width: 100 }} alt="icon-page-not-found" />
	  </div>
    </div>
  );
};

export default NotFound;
