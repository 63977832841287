/**
 * Pięć klas kodów odpowiedzi HTTP
 * 100-199 – kody informacyjne.
 * 200-299 – kody powodzenia.
 * 300-399 – kody przekierowań
 * 400-499 – kody błędów klienta.
 * 500-599 – kody błędu serwera HTTP.
 * 
 * Tutorial Toastify:
 * https://geshan.com.np/blog/2023/05/react-toastify/
 * 
 * Tutorial wylapywanie bledow
 * https://dmitripavlutin.com/javascript-fetch-async-await/
 */
import { Routes, Route, Link, NavLink, useNavigate, useLocation } from 'react-router-dom';

/**
 * Skopiowane z Dave Gray Axios
 */
import { useContext } from "react";
import { useRef, useState, useEffect } from 'react';
import AuthContext from "../context/AuthProvider";
/**
 * END Skopiowane z Dave Gray Axios
 */


import axios from 'axios'
import './AdminHeaderCSS.css';

axios.defaults.withCredentials = true

const LOGOUT_URL = '/logout';

const Header = () => {

	const { setAuth } = useContext(AuthContext);
	const { auth } = useContext(AuthContext);

  let userInfo = false;
  const navigate = useNavigate();
  const location = useLocation();
  const pathAfterLogout = "/admin/login";

	const logoutHandler = async () => {
	   /**
      * Funkcja wylogowania wykonuje:
      * - wywołanie z API sciezki która skasuje cookie z Tokenem
      *   cookie to jest typu HttpOnly wiec jego kasowanie jest realizowane przez wykonanie przez 
      *   serwer kodu:
      *   const logoutUser = (req, res) => {
          res.cookie('jwt', '', {
            httpOnly: true,
            expires: new Date(0),
          });
          res.status(200).json({ message: 'Logged out successfully' });
          }; 

      * patrz TrawersyMedia backend userController.js    
      */
	  setAuth({});
    try {
      const response = await axios.get(LOGOUT_URL,{ withCredentials: true });
      //console.log("Response STATUS: "+response.status);
      //console.log("response: "+response);
      //console.log("response.data: "+response.data);

      navigate(pathAfterLogout, { replace: true });
	  

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <header>
		  <div id="topnavbarContainer" className="containerFlexBox">

				<div id="topnavbarContainerLeft">
				
          <div className="containerFlexBox">
                <div>
                  <p className="titleBold"><b>MiniCMS React - Panel Administracyjny</b></p>
                </div>
                <div>
                <NavLink
                  to='/admin'
                  className={({ isActive, isPending }) =>
                    isPending ? "linkTopnavbarNotActive" : isActive ? "linkTopnavbarActive" : ""
                  }
                  >
                  Panel Admin
                </NavLink>
                </div>
                <div>
                <NavLink
                  to='/'
                  className={({ isActive, isPending }) =>
                    isPending ? "linkTopnavbarNotActive" : isActive ? "linkTopnavbarActive" : ""
                  }
                  >
                  Część publiczna
                </NavLink>
				
                </div>
          </div>
		  <p>Baza danych: Dave_Gray_React_JWT_Refresh_Tokens_Cookies_Axios</p>
				</div>




				<div id="topnavbarContainerRight">
          {auth.user ? (
            <>
    
              <div className="containerFlexBoxRight">
			  
                <div style={{ paddingTop: 12 }} className="middle">
					<p>Zalogowany użytkownik: <b>{auth.user} / {auth.roles}</b></p>
				</div>
                
				
				<div className="middle">
				
				
					<Link to='/admin/profile'>Profil użytkownika</Link>&nbsp;&nbsp;
					<Link to='/admin/profile'>
					<img src="/Icon_One_User.png" style={{ width: 30 }} alt="icon-edit-user" />
					</Link>
				</div>
                <div className="middle">
					<Link onClick={logoutHandler}>Wyloguj</Link>&nbsp;&nbsp;
					
					<Link onClick={logoutHandler}>
					<img src="/logout-256.png" style={{ width: 30 }} alt="icon-edit-user" />
					</Link>
				</div>
              </div>
          
            </>
            ) : (
            <>
              <div className="containerFlexBoxRight">
				
                <div className="middle">
					<Link to='/admin/login'>zaloguj</Link>&nbsp;&nbsp;
					<Link to='/admin/login'>
					<img src="/Icon_Login_Green.png" style={{ width: 40 }} alt="icon-login" />
					</Link>
				</div>
                <div style={{ paddingTop: 12 }} className="middle">
					<Link to='/admin/registeruser'>rejestruj</Link>&nbsp;&nbsp;
					<Link to='/admin/registeruser'>
					<img src="/Icon_Users_Add_PNG_Transparent.png" style={{ width: 60 }} alt="icon-user-register" />
					</Link>
				</div>
              </div>
            </>
            )}
				</div>
			</div>
    </header>
  );
};
export default Header;
