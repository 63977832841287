
import axios from 'axios'
//import './../App.css'


axios.defaults.withCredentials = true




const PublicHome = () => {
	
	const zalogujUzytkownikaGet = () => {
  axios.get('http://localhost:5000/api/users/zalogujuzytkownika',{ withCredentials: true }).then((res) =>{
    console.log(res.data)
  })
}

const createCookie = () => {
  axios.get('http://localhost:5000',{ withCredentials: true }).then((res) =>{
    console.log(res.data)
  })
}
const deleteCookie = () => {
  axios.delete('http://localhost:5000/api/cookie/deletecookie',{ withCredentials: true }).then((res) =>{
    console.log(res.data)
  })
}
	
	
  return (
    <div>
	xxxxxxxxxxxxxxxxxx
      <p>PublicHome Strona głowna części publicznej</p>
	  
	  <div className="App">
      <p>TESTY HTTP ONLY COOKIE DEMO</p>
			<div className="box">
			<button className="button green" onClick={zalogujUzytkownikaGet}>Zaloguj Użytkownika</button>
        <button className="button green" onClick={createCookie}>Create Cookies</button>
        <button className="button yellow">Renew Cookies</button>
        <button className="button red" onClick={deleteCookie}>Delete Cookie</button>
      </div>
		</div>
	  
    </div>
  );
};

export default PublicHome;
