/**
 * Pięć klas kodów odpowiedzi HTTP
 * 100-199 – kody informacyjne.
 * 200-299 – kody powodzenia.
 * 300-399 – kody przekierowań
 * 400-499 – kody błędów klienta.
 * 500-599 – kody błędu serwera HTTP.
 * 
 * Tutorial Toastify:
 * https://geshan.com.np/blog/2023/05/react-toastify/
 * 
 * Tutorial wylapywanie bledow
 * https://dmitripavlutin.com/javascript-fetch-async-await/
 */
import React, { Component, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ReactPaginate from 'react-paginate';
import { useRef } from "react";
//Import na potrzeby okien modalnych bootstrap
//import Modal from 'react-bootstrap/Modal';
//import Button from 'react-bootstrap/Button';
///import Form from 'react-bootstrap/Form';
import './AdminRolesUserDictionaryComponent.css';
import './../css/modal.css';
//Komunikaty toastify
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let messageResponse = "";
let showTablesData = true;

const RolesUserDictionaryComponent = () => {
  // form validation rules
  const axiosPrivate = useAxiosPrivate();
  const validationSchema = Yup.object().shape({
        nameRoleUser: Yup.string()
            .required('Nazwa roli jest wymagana!')
            .min(5, 'Nazwa roli min 5 znaków!')
            .max(50, 'Nazwa roli max 50 znaków!'),
        descriptionRoleUser: Yup.string()
            .required('Opis roli jest wymagany!')
            .min(5, 'Opis roli min 5 znaków!')
            .max(50, 'Opis roli max 200 znaków!'),
      
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const [actualPage, setActualPage] = useState(false);
    const [useModal, setUseModal] = useState(false);

    /** Widocznosc ramek z komunikatem powodzenie / blad  */
    const [visibleBoxError, setVisibleBoxError] = useState(false);
    const [visibleBoxOK, setVisibleBoxOK] = useState(false);
    /** END Widocznosc ramek z komunikatem powodzenie / blad  */

    /**
     * #####################################################################
     * OBSŁUGA OKNA MODALNEGO KASOWANIE ROLI UZYTKOWNIKA
     */

    /** Okreslenie modala kasowanie */
    const modalDeleteRoleUserRef = useRef(null);
    /** Ustawienie czy okno modalne widoczne */
    const [isModalDeleteRoleUserOpen, setVisibleModalDeleteRoleUSer] = useState(false);

    /** Setting state na potrzeby okna modalnego obsługujacego kasowanie */
    const [stateDeleteId, setDeleteId] = useState("");
    const [stateRoleNameDelete, setNameRoleDelete] = useState("");

    //Ustawianie stanu okna modalnego kasowania rekordów
    const closeModalConfirmDeleteRoleUser = () => {
      setVisibleModalDeleteRoleUSer(false);
      setUseModal(true);
    };

    /** Function uruchamiana przez klikniecie buttona OK w oknie modalnym */
    const handleDeleteRoleUser = () => {  
      console.log("Uruchamiam handleDeleteRoleUser");
      console.log("Stan z id do kasowania: "+stateDeleteId+ " / "+ stateRoleNameDelete);
      deleteRoleUser(stateDeleteId, stateRoleNameDelete);
      setVisibleModalDeleteRoleUSer(false);
    }

  /** Function uruchamiana przez klikniecie buttona kasuj w tabeli */
  const handleClickButtonDelete = async (idDeleteRoleUSer, nameRoleUser) => {
      setDeleteId(idDeleteRoleUSer);
      setNameRoleDelete(nameRoleUser);
      setVisibleModalDeleteRoleUSer(true);
      setUseModal(true);
      console.log("ID kasowanego rekordu: "+idDeleteRoleUSer+" / "+ nameRoleUser);
  };

  //deleting role user
  const deleteRoleUser = async (idDictionaryRoleUser, nameRoleUser) => {
    console.log("Uruchamiam funkcje deleteRoleUser");
    setUseModal(true);
    clickResetForm();
    let response;
    try {
          response = await fetch("http://localhost:3500/api/users/rolesusersdictionary", {
              method: "DELETE",
              crossDomain: true,
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
              },
              body: JSON.stringify({
                  idDictionaryRoleUser: idDictionaryRoleUser,
                  nameRoleUser: nameRoleUser,
              }),
            })

            /**
             * Zapytanie udało się 
             * Wystepują kody HTTP 100-299
             */
        if (response?.ok) {
            const messageFromServer = await response.json();
            console.log('Response OK '+messageFromServer.message);
            toast.success(messageFromServer.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000, //3 seconds
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                transition: Slide
              });
              showBoxInfoResponseOK();
              messageResponse = messageFromServer.message;
              //getAllUser();
              getPaginatedUsers();

        } else {
            const messageFromServer = await response.json();
            toast.error('Wystąpił błąd: '+messageFromServer.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            showBoxInfoResponseError();
            messageResponse = "Wystąpił błąd: "+messageFromServer.message;
            console.log(`HTTP Response Code: ${response?.status}`)
            getPaginatedUsers();
        }//END if (response?.ok) {

  } catch (error) {
        /** Wystapił błąd */
        console.log('Całe zapytanie sie nie udało', error);
        toast.error('Wystąpił błąd: '+error, {
            position: toast.POSITION.TOP_RIGHT
        });
        showBoxInfoResponseError();
        messageResponse = "Wystąpił błąd: "+error;
        getPaginatedUsers();
    }
};

/** 
  * END OBSŁUGA OKNA MODALNEGO KASOWANIE ROLI UZYTKOWNIKA
  * #####################################################################
*/





  /**
    * #####################################################################
    * OBSŁUGA OKNA MODALNEGO UPDATE ROLI UZYTKOWNIKA
   */

  /** Okreslenie modala kasowanie */
  const modalUpdateRoleUserRef = useRef(null);
        
  /** Ustawienie czy okno modalne widoczne */
  const [isModalUpdateRoleUserOpen, setVisibleModalUpdateRoleUSer] = useState(false);

  /** Setting state na potrzeby okna modalnego obsługujacego UPDATE */
  const [stateUpdateId, setUpdateId] = useState("");
  const [stateRoleNameUpdateBefore, setNameRoleUpdateBefore] = useState("");
  const [stateRoleNameUpdate, setNameRoleUpdate] = useState("");
  const [stateRoleDescriptionUpdate, setDescriptionRoleUpdate] = useState("");

  const closeModalUpdateRoleUser = async () => {
      setVisibleModalUpdateRoleUSer(false);
      setUseModal(true);
  };

  /** Function uruchamiana przez klikniecie buttona UPDATE w tabeli */
  const handleClickButtonUpdate = async (idUpdateRoleUSer, nameRoleUserUpdate, descriptionRoleUserUpdate) => {
    clickResetForm();
    setUpdateId(idUpdateRoleUSer);
    setNameRoleUpdateBefore(nameRoleUserUpdate);
    setNameRoleUpdate(nameRoleUserUpdate);
    setDescriptionRoleUpdate(descriptionRoleUserUpdate);
    //Wyswietlenie modala UpdateRoleUser (element Dialog HTML)
    setVisibleModalUpdateRoleUSer(true);
    setUseModal(true);
    console.log("Dane do edycji: "+idUpdateRoleUSer+" / "+ nameRoleUserUpdate+ " / "+descriptionRoleUserUpdate);
  };









  /** Function uruchamiana przez klikniecie buttona OK w oknie modalnym */
  const handleSendFormUpdateRoleUser = async () => {
    console.log("Uruchamiam handleSendFormUpdateRoleUser");
    setUseModal(true);
    /** 
     * Nowe wartosci pol form znajduja sie w state komponentu 
     * const [stateRoleNameUpdate, setNameRoleUpdate] = useState("");
     * const [stateRoleDescriptionUpdate, setDescriptionRoleUpdate] = useState("");
    */
    let response;
    let oldRoleUserName = stateRoleNameUpdateBefore;
    let newRoleUserName = stateRoleNameUpdate;
    let newRoleUserDescription = stateRoleDescriptionUpdate;
    let idRoleUserUpdate = stateUpdateId;
    try {
        response = await fetch("http://localhost:3500/api/users/rolesusersdictionary", {
                method: "PUT",
                crossDomain: true,
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify({
                    idRoleUserUpdate,
                    oldRoleUserName,
                    newRoleUserName,
                    newRoleUserDescription,
                }),
              })

              /**
               * Zapytanie udało się 
               * Wystepują kody HTTP 100-299
               */
            if (response?.ok) {
                
                const messageFromServer = await response.json();
                console.log('Response OK '+messageFromServer.message);
          
                toast.success(messageFromServer.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000, //3 seconds
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    transition: Slide
                  });
                  showBoxInfoResponseOK();
                  messageResponse = messageFromServer.message;
                  
                  //Run odswiezenie tabeli z paginacja
                  getPaginatedUsers();

            } else {

                const messageFromServer = await response.json();
                
                toast.error('Wystąpił błąd: '+messageFromServer.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                showBoxInfoResponseError();
                messageResponse = "Wystąpił błąd: "+messageFromServer.message;

                console.log(`HTTP Response Code: ${response?.status}`)
                
            }//END if (response?.ok) {

    } catch (error) {
            /** Wystapił błąd */
            console.log('Całe zapytanie sie nie udało', error);
            toast.error('Wystąpił błąd: '+error, {
                position: toast.POSITION.TOP_RIGHT
            });
            showBoxInfoResponseError();
            messageResponse = "Wystąpił błąd: "+error;
    }
    setVisibleModalUpdateRoleUSer(false);
  }//END const handleSendFormUpdateRoleUser = async () => {
    
	
	
	
	
	
	
	
  /**
   * ##################################################################
   * Ustawienia State komponentu zwiazane z pobraniem i paginacja danych
   */
  const [data, setData] = useState([]);
  const [limit, setLimit]=useState(3);
  const [pageCount,setPageCount]=useState(1);
  const currentPage=useRef();
   
  const showBoxInfoResponseError = () => {
        setVisibleBoxError(true);
  };
    const hideBoxInfoResponseError = () => {
        setVisibleBoxError(false);
    };
    const showBoxInfoResponseOK = () => {
        setVisibleBoxOK(true);
    };
    const hideBoxInfoResponseOK = () => {
        setVisibleBoxOK(false);
    };
    const hideAllBoxInfoResponse = () => {
        setVisibleBoxError(false);
        setVisibleBoxOK(false);
    };
    const clickResetForm = () => {
      console.log("clickResetForm");
        reset();
        hideAllBoxInfoResponse();
    };
    const cursorFieldForm = () => {
        hideAllBoxInfoResponse();
    }

    useEffect(() => {
      /** Zarzadzanie wyswietlaniem / ukrywaniem modala modalDeleteRoleUser  */
      const modalElementDeleteRoleUser = modalDeleteRoleUserRef.current;
      const modalElementUpdateRoleUser = modalUpdateRoleUserRef.current;

      /** Open modal delete role */
      if (isModalDeleteRoleUserOpen) {
          modalElementDeleteRoleUser.showModal();
      } else {
          modalElementDeleteRoleUser.close();
      }
      /** Open modal update role */
      if (isModalUpdateRoleUserOpen) {
          modalElementUpdateRoleUser.showModal();
      } else {
          modalElementUpdateRoleUser.close();
      }
      
      if(!useModal) {
        currentPage.current=1;
        console.log("useEffect-line-199 / useModal "+useModal);
        getPaginatedUsers();
      }
      setUseModal(false);
    }, [isModalDeleteRoleUserOpen, isModalUpdateRoleUserOpen]);




/**
 * FUNKCJA URUCHAMIANA PRZY ZALADOWANIU KOMPONENTU
 */
const getPaginatedUsers = async data => {
	 
	console.log("URUCHAMIAM getPaginatedUsers");
      let page='';
      page = currentPage.current;
      console.log("actualPage"+actualPage);
  
  
      try {
          const response = await axiosPrivate.get(`http://localhost:3500/api/users/rolesusersdictionary?page=${page}&limit=${limit}`);
                console.log(response.data);
                
            } catch (err) {
                console.error(err);
                //navigate('/admin/login', { state: { from: location }, replace: true });
            }
			
			
}


    function getPaginatedUsersOld(){
      console.log("URUCHAMIAM getPaginatedUsers");
      let page='';
      page = currentPage.current;
      console.log("actualPage"+actualPage);
        fetch(`http://localhost:3500/api/users/rolesusersdictionary?page=${page}&limit=${limit}`, {
          method: "GET",
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data, "userData");
            setPageCount(data.pageCount);
            setData(data.result)
          });
    }
	
	
	
	function setCookieTest(){
      console.log("URUCHAMIAM setCookieTest");
     
	 
	 var headers = new Headers();
headers.append('Content-Type', 'application/json');
headers.append('Accept', 'application/json');

fetch('http://localhost:3500/ustawcookie', {
    method: 'GET',
	credentials: 'include'
   
})
	 
	 /*
        fetch(`http://localhost:3500/ustawcookie`, {
          method: "GET",
		credentials: 'include'
        })
          .then((res) => res.json())
          .then((data) => {
            //console.log(data);
            
          });
		  */
		  
		  
    }
	
	



//pagination
//Ta funkcja jest uruchamiana przez wtyczke ReactPaginate
function handlePageClick(e) {
    console.log(e);
    setActualPage(e.selected+1);
    currentPage.current=e.selected+1;
    clickResetForm();
    getPaginatedUsers();
}




const onSubmit = async data => {
	 hideAllBoxInfoResponse();
  let nameRoleUser = data.nameRoleUser;
  let descriptionRoleUser = data.descriptionRoleUser;
      try {
          const response = await axiosPrivate.post('http://localhost:3500/api/users/rolesusersdictionary', 
		  JSON.stringify({ nameRoleUser, descriptionRoleUser }));
		  
		  
                
				
				 if (response.status < 400) {
					 console.log(response.status);
					 console.log("Response OK");
					 console.log(response.data);
					 
				 } else {

               console.log(response.status);
					 console.log("Response NOT OK");
					 console.log(response.data);
                 
            }//END if (response?.ok) {
				
				
				
                //isMounted && setUsers(response.data);
            } catch (err) {
                console.error(err);
                //navigate('/admin/login', { state: { from: location }, replace: true });
            }
}





/**
 * Wysłanie formularza
 */
const onSubmitOld = async data => {
  hideAllBoxInfoResponse();
  let nameRoleUser = data.nameRoleUser;
  let descriptionRoleUser = data.descriptionRoleUser;
  let response;
    try {
            //response = await fetch("http://localhost:3500/register1", {
            response = await fetch("http://localhost:3500/api/users/rolesusersdictionary", {
                method: "POST",
                crossDomain: true,
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify({
                    nameRoleUser,
                  descriptionRoleUser,
                }),
              })

              /**
               * Zapytanie udało się 
               * Wystepują kody HTTP 100-299
               */
            if (response?.ok) {
                const messageFromServer = await response.json();
                console.log('Response OK '+messageFromServer.message);
                toast.success(messageFromServer.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000, //3 seconds
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    transition: Slide
                  });
                  showBoxInfoResponseOK();
                  messageResponse = messageFromServer.message;
                  //Run odswiezenie tabeli z paginacja
                  getPaginatedUsers();

            } else {

                const messageFromServer = await response.json();
                toast.error('Wystąpił błąd: '+messageFromServer.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                showBoxInfoResponseError();
                messageResponse = "Wystąpił błąd: "+messageFromServer.message;
                console.log(`HTTP Response Code: ${response?.status}`)
                 
            }//END if (response?.ok) {

        } catch (error) {
            /** Wystapił błąd */
            console.log('Całe zapytanie sie nie udało', error);
            toast.error('Wystąpił błąd: '+error, {
                position: toast.POSITION.TOP_RIGHT
            });
            showBoxInfoResponseError();
            messageResponse = "Wystąpił błąd: "+error;
        }
}//END const onSubmit = async data => {
  




/**
 * Funkcje uruchamiane w momencie zmiany value na polach formularza w modalu "edycja roli uzytkownika"
 * 
 */
const handleEditFormNameRole = event => {
    setNameRoleUpdate(event.target.value);
    // 👇️ this is the input field itself
    console.log(event.target);
    // 👇️ this is the new value of the input
    console.log(event.target.value);
  };

  const handleEditFormDescriptionRole = event => {
    setDescriptionRoleUpdate(event.target.value);
    // 👇️ this is the input field itself
    console.log(event.target);
    // 👇️ this is the new value of the input
    console.log(event.target.value);
  };

/** END Funkcje związane z oknem modalnym UPDATE */

return (
    <>
      <div className="containerRoleEdit">
	  
	  <Link onClick={setCookieTest}>Ustaw Cookie</Link>&nbsp;&nbsp;
	  
        <div className="div-inline-block width80percent">
            <p><b>Edycja ról użytkowników</b></p>
        </div>
        <div className="div-inline-block">
            <img src="/Icon_Edit_User_Transparent.png" style={{ width: 90 }} alt="icon-edit-user" />
        </div>
        <div>
          {visibleBoxError && <div className="messageResponseBox" id="messageResponseError">{messageResponse}</div>}
          {visibleBoxOK && <div className="messageResponseBox" id="messageResponseOK">{messageResponse}</div>}
        </div>
            {showTablesData &&
              <form id="formEditRoleUser" onSubmit={handleSubmit(onSubmit)}>
                <label className="label">Nazwa roli użytkownika</label>
                <input 
                  {...register("nameRoleUser")} 
                  onClick={cursorFieldForm}
                  maxLength="50"
                />
                <p className="form-validation-error">{errors.nameRoleUser?.message}</p>
                <label className="label">Opis roli</label>
                <textarea  
                  {...register("descriptionRoleUser")}
                  onClick={cursorFieldForm}
                  maxLength="200"
                />
                <p className="form-validation-error">{errors.descriptionRoleUser?.message}</p>
                <p></p>

                <div className="container-flexbox-1">
                  <div style={{ width: '70%', padding:'3px' }}>
                    <button type="submit" className="buttonSaveData">Zapisz dane</button>
                  </div>
                  <div style={{ width: '30%', padding:'3px' }}>
                  <button type="button" onClick={() => clickResetForm()} className="buttonResetData">Reset</button>
                  </div>
                </div>
              </form>
            }
            <table className="table-data" id="tableDataListRoles">
                <thead>
                    <tr>
                        <th>Nazwa roli użytkownika</th>
                        <th>Opis roli użytkownika</th>
                        <th>Data utworzenia</th>
                        <th>Edytuj</th>
                        <th>Kasuj</th>
                    </tr>
                </thead>
                <tbody>
                {data.map((dataInTable) => (
                    <tr key={dataInTable._id}>
                        <td>{dataInTable.nameRoleUser}</td>
                        <td>{dataInTable.descriptionRoleUser}</td>
                        <td>{dataInTable.createdAt}</td>
                        <td>
                          <button id="tableRolesButtonEdit" onClick={() => handleClickButtonUpdate(dataInTable._id, dataInTable.nameRoleUser, dataInTable.descriptionRoleUser)}>Edytuj</button>
                        </td>
                        <td>
                            <button id="tableRolesButtonDelete" onClick={() => handleClickButtonDelete(dataInTable._id, dataInTable.nameRoleUser)}>Kasuj</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div>&nbsp;</div>
            <ReactPaginate
                    breakLabel="..."
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={5} 
                    previousLabel={"Wstecz"}
                    nextLabel={"Dalej"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                    forcePage={currentPage.current-1}
            />       
      </div>
      {/* Modal wykorzystujacy element HTMl Dialog  */}
      {/* Modal HTML Dialog Delete Role */}
      <dialog ref={modalDeleteRoleUserRef} className="modal-confirm-delete-role-user">
        <div className="width100percent alignRight">
          <div className="cursor-pointer" onClick={()=>closeModalConfirmDeleteRoleUser()}>
            <img src="/Close_Icon_PNG_Transparent.png" style={{ width: 20 }} alt="close" />
          </div>
        </div>
        <p><b>Potwierdź kasowanie roli użytkownika</b></p>
        <p><b>{stateRoleNameDelete}</b></p>
        <hr></hr>
        <div className="center" style={{ width: 180 }}>
          <img src="/Atention_Triangle_Icon.png" style={{ width: 90 }} alt="profile" />
          <img src="/Delete_Trash_Red_Icon.png" style={{ width: 90 }} alt="profile" />
        </div>  
        <p>Czy chcesz skasować rolę użytkownika: <b>{stateRoleNameDelete}</b> ?</p>
        <button className="buttonSaveData" onClick={handleDeleteRoleUser}>Usuń role użytkownika</button>
        <button className="buttonResetData" onClick={closeModalConfirmDeleteRoleUser} style={{ marginTop: 10 }}>Anuluj</button>
      </dialog>
      {/* END Modal HTML Dialog Delete Role */}

      {/* Modal HTML Dialog Edit Role User */}
        <dialog ref={modalUpdateRoleUserRef} className="modal">
        <div className="width100percent alignRight">
          <div className="cursor-pointer" onClick={()=>closeModalUpdateRoleUser()}>
            <img src="/Close_Icon_PNG_Transparent.png" style={{ width: 20 }} alt="close" />
          </div>
        </div>
          <p><b>Edycja roli użytkownika</b></p>
          <p><b>{stateRoleNameUpdateBefore}</b></p>
          <hr></hr>
          <div className="center" style={{ width: 100 }}>
            <img src="/Icon_Edit_PNG_Transparent.png" style={{ width: 70 }} alt="profile" />
          </div>  
            <form>
              <label>Nazwa roli użytkownika:</label>
              <input maxLength="50" id="newRoleNameUpdate" name="newRoleNameUpdate" onChange={handleEditFormNameRole} value={stateRoleNameUpdate}/>
              <p></p>
              <label>Opis roli użytkownika:</label>
              <textarea 
                name="newRoleDescriptionUpdate"
                maxLength="200"
                rows={2}
                onChange={handleEditFormDescriptionRole}
                value={stateRoleDescriptionUpdate}
                >
              </textarea>
          </form>
          <hr></hr>
          <button className="buttonSaveData" onClick={handleSendFormUpdateRoleUser}>Modyfikuj rolę Użytkownika</button>
          <button className="buttonResetData" onClick={closeModalUpdateRoleUser} style={{ marginTop: 10 }}>Anuluj</button>
        </dialog>
    </>
  ); 
};
export default RolesUserDictionaryComponent;

  
